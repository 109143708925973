import axios from "axios"
import { useMemo } from "react"


//export const BASE_URL = "https://sermobilv2.serodasan.com"
//export const BASE_URL = "http://10.10.10.100:8096"
//export const BASE_URL = "http://10.10.10.88:8096"
//export const BASE_URL = "http://localhost:8096"
export const BASE_URL = process.env.REACT_APP_API_URL;


axios.defaults.baseURL = `${BASE_URL}`
// axios.defaults.withCredentials = true

const ApiProvider = ({ children }) => {
    useMemo(() => {
        axios.interceptors.request.use(async (config) => {



            const token = localStorage.getItem("token")
            axios.defaults.headers["Accept-Language"] = "tr"
            if (token && token !== null) {
                config.headers["Authorization"] = `Bearer ${token}`
            }





            return config
        }, async () => {
        });
        axios.interceptors.response.use(async (response) => {

            // if (response.status === 401) {
            //     window.location.href = "/login"
            //     return response
            // }

            if (response?.data?.token) {
                localStorage.setItem("token", response?.data?.token)
            }

            return response;
        }

        );
    }, [])
    return children
}



export default ApiProvider