import { Box, Button, Divider, Grid, MenuItem, Modal, Paper, TextField, Typography, useTheme } from "@material-ui/core"
import React, { useEffect, useState } from 'react'
import { changeEmailRequest, changePasswordRequest, changePhoneRequest, meRequest, updateCorporateUser, updateIndividualUser, verifyEmailCodeRequest, verifyPhoneCodeRequest } from "../../../api/controllers/account-controller"
import { getAllRequest } from "../../../api/controllers/addresses-controller"




const Page = () => {

    const theme = useTheme()

    /*   const handleCreate = async (e) => {
          e.preventDefault()
  
  
          let res = await saveSafeZoneRequest({ latLngList, safeZoneName })
  
          console.log(res?.data)
  
          await fetchSafeZones()
  
          setCreateModalOpen(false)
  
      } */
    const [email, setEmail] = useState("")
    const [identificationNumber, setIdentificationNumber] = useState("")
    const [taxNumber, setTaxNumber] = useState("")
    const [phone, setPhone] = useState("")
    const [title, setTitle] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [userType, setUserType] = useState("")

    const [individualEditModalOpen, setIndividualEditModalOpen] = useState(false)
    const [corporateEditModalOpen, setCorporateEditModalOpen] = useState(false)

    const [editFirstName, setEditFirstName] = useState("")
    const [editLastName, setEditLastName] = useState("")
    const [editIndividualProvince, setEditIndividualProvince] = useState("")
    const [illerBireysel, setIllerBireysel] = useState([])
    const [illerKurumsal, setIllerKurumsal] = useState("")

    const [editTitle, setEditTitle] = useState("")
    const [editCorporateProvince, setEditCorporateProvince] = useState("")

    const [editPhone, setEditPhone] = useState("")
    const [editPhoneModalOpen, setEditPhoneModalOpen] = useState(false)

    const [verifyPhoneCode, setVerifyPhoneCode] = useState("")
    const [verifyPhoneCodeModalOpen, setVerifyPhoneCodeModalOpen] = useState(false)

    const [editMail, setEditMail] = useState("")
    const [editMailModalOpen, setEditMailModalOpen] = useState(false)

    const [verifyMailCode, setVerifyMailCode] = useState("")
    const [verifyMailCodeModalOpen, setVerifyMailCodeModalOpen] = useState(false)

    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false)
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordAgain, setNewPasswordAgain] = useState("")

    const fetchProvince = async () => {
        let res = await getAllRequest()
        if (res?.data) {
            console.log(res?.data)
            setIllerBireysel(res.data)
            setIllerKurumsal(res.data)
        }
    }

    const fetchAccountInformation = async () => {

        let res = await meRequest()
        if (res?.data) {
            console.log(res?.data)
            setEmail(res?.data.email)
            setIdentificationNumber(res?.data?.identificationNumber)
            setTaxNumber(res?.data?.taxNumber)
            setPhone(res?.data?.phone)
            setTitle(res?.data?.commercialTitle)
            setFirstName(res?.data?.firstName)
            setLastName(res?.data?.lastName)
            setUserType(res?.data?.userType)
            setEditFirstName(res?.data?.firstName)
            setEditLastName(res?.data?.lastName)
            setEditIndividualProvince(res?.data?.province)
            setEditTitle(res?.data?.title)
            setEditCorporateProvince(res?.data?.province)
            setEditPhone(res?.data?.phone)
            setEditMail(res?.data?.email)
        }
    }
    const handleEditIndividual = async (e) => {
        e.preventDefault()

        try {
            let res = await updateIndividualUser({
                firstName: editFirstName,
                lastName: editLastName,
                province: editIndividualProvince
            })
            if (res) {
                setEditFirstName("")
                setEditLastName("")
                setEditIndividualProvince("")

            }
            await fetchAccountInformation()

        } catch (error) {
            console.log(error?.response?.data)
            alert(error?.response?.data)
        }

        setIndividualEditModalOpen(false)

    }

    const handleEditCorporate = async (e) => {
        e.preventDefault()

        try {
            let res = await updateCorporateUser({
                province: editCorporateProvince,
                title: editTitle
            })
            if (res) {
                setEditCorporateProvince("")
                setEditTitle("")


            }

            await fetchAccountInformation()

        } catch (error) {

            console.log(error.response.data)
            alert(error?.response?.data)
        }

        setCorporateEditModalOpen(false)

    }
    const handleEditPhone = async (e) => {
        e.preventDefault()

        try {
            let res = await changePhoneRequest({
                newPhone: editPhone
            })
            if (res) {
                setEditPhone("")
                setVerifyPhoneCodeModalOpen(true)
            }

        } catch (error) {

            console.log(error.response.data)
            alert(error?.response?.data)
        }
        setEditPhoneModalOpen(false)
    }
    const handleVerifyPhone = async (e) => {
        e.preventDefault()

        try {
            let res = await verifyPhoneCodeRequest({
                code: verifyPhoneCode
            })
            if (res) {

                setVerifyPhoneCodeModalOpen(false)
            }

        } catch (error) {

            console.log(error.response.data)
            alert(error?.response?.data)
        }
        setEditPhoneModalOpen(false)
    }
    const handleEditMail = async (e) => {
        e.preventDefault()

        try {
            let res = await changeEmailRequest({
                newEmail: editMail
            })
            if (res) {
                setEditMail("")
                setVerifyMailCodeModalOpen(true)
            }

        } catch (error) {

            console.log(error.response.data)
            alert(error?.response?.data)
        }
        setEditMailModalOpen(false)
    }
    const handleVerifyEmail = async (e) => {
        e.preventDefault()

        try {
            let res = await verifyEmailCodeRequest({
                code: verifyMailCode
            })
            if (res) {

                setVerifyMailCodeModalOpen(false)
            }

        } catch (error) {

            console.log(error.response.data)
            alert(error?.response?.data)
        }
        setEditMailModalOpen(false)
    }
    const handleChangePassword = async (e) => {
        e.preventDefault()
        if (newPassword === newPasswordAgain) {
            if (oldPassword.length < 8) {
                alert("Eski Parolanız 8 Haneden Küçük Olamaz")
            }
            else if (newPassword.length < 8) {
                alert("Yeni Parolanız 8 Haneden Küçük Olamaz")
            }
            else if (newPasswordAgain.length < 8) {
                alert("Yeni Parola Tekrarınız 8 Haneden Küçük Olamaz")
            }
            try {
                let res = await changePasswordRequest({
                    newPassword: newPassword,
                    oldPassword: oldPassword,
                    repeatNewPassword: newPasswordAgain
                })
                if (res) {
                    setNewPassword("")
                    setNewPasswordAgain("")
                    setOldPassword("")

                }

            } catch (error) {

                console.log(error.response.data)
                alert(error?.response?.data)
            }

        }
        else {
            alert("Yeni Parolalar Uyuşmamaktadır")
        }

        setChangePasswordModalOpen(false)
    }
    useEffect(() => {
        (
            async () => {
                await fetchAccountInformation()
                await fetchProvince()
            }
        )()

        return () => {

        }
    }, [])


    return (
        <Box p={3}>

            <Paper
                elevation={0}
                sx={{
                    display: "flex",
                    padding: theme.spacing(2),
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: "row" },
                    background: "#FBE9E7",
                    boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.15)"
                }}
            >
                <Typography variant="h4" color="secondary">Hesabım</Typography>
                <div
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: "row" }
                    }}
                >
                    {/* <Button onClick={
                        userType === "IndividualUser" ? () => setIndividualEditModalOpen(true) : () => setCorporateEditModalOpen(true)
                    }
                        disableElevation sx={{

                            margin: theme.spacing(1),
                            width: { xs: "100%", sm: "100%", md: "auto", lg: "auto" }
                        }} variant="contained" color="primary">Düzenle</Button> */}
                </div>

            </Paper>

            <Paper
                elevation={0}
                sx={{
                    padding: "30px",
                    marginTop: theme.spacing(2),
                }}
            >
                <Typography color="#DC804F">Genel Bilgiler</Typography>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, width: "100%" }}>


                    {
                        userType === "IndividualMember" &&
                        <Box sx={{ width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" }, display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Typography color="#757575">İsim Soyisim: </Typography>
                            <Typography marginLeft="5px" fontWeight="bold" color="#757575">
                                {firstName} {lastName}
                            </Typography>
                        </Box>
                    }
                    {
                        userType === "CorporateMember" &&
                        <Box sx={{ width: { xs: "100%", sm: "100%", md: "50%", lg: "50%" }, display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <Typography color="#757575">Şirket Unvanı: </Typography>
                            <Typography marginLeft="5px" fontWeight="bold" color="#757575">
                                {title}
                            </Typography>
                        </Box>
                    }



                    {
                        userType === "IndividualMember" &&
                        <Box sx={{ width: { xs: "100%", sm: "100%", md: "auto", lg: "auto" }, display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {/*  <img src="/assets/LocationOnRounded.svg" /> */}
                            <Typography color="#757575">TC: </Typography>
                            <Typography marginLeft="5px" fontWeight="bold" color="#757575"> {identificationNumber}</Typography>

                        </Box>
                    }
                    {
                        userType === "CorporateMember" &&
                        <Box sx={{ width: { xs: "100%", sm: "100%", md: "auto", lg: "auto" }, display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {/*  <img src="/assets/LocationOnRounded.svg" /> */}
                            <Typography color="#757575">Vergi Numarası: </Typography>
                            <Typography marginLeft="5px" fontWeight="bold" color="#757575"> {taxNumber}</Typography>

                        </Box>
                    }

                </Box>
                <Divider sx={{ marginTop: "30px" }}></Divider>
                <Typography marginTop="30px" color="#DC804F">İletişim Bilgileri</Typography>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, width: "100%" }}>
                    <Box sx={{ width: { xs: "100%", sm: "50%", md: "50%", lg: "50%" }, display: "flex", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: { xs: "baseline", sm: "baseline", md: "center", lg: "center" } }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <img src="/assets/PhoneRounded.svg" />
                            <Typography color="#757575">Telefon Numarası: </Typography>
                        </Box>
                        <Typography marginLeft="5px" fontWeight="bold" color="#757575"> {phone}</Typography>
                        {/*  <Button disableElevation
                            onClick={() => setEditPhoneModalOpen(true)}
                            sx={{
                                margin: theme.spacing(1),
                                width: { xs: "100%", sm: "100%", md: "auto", lg: "auto" },
                            }} variant="contained" color="primary">Değiştir</Button> */}
                    </Box>
                    {/*  <Box sx={{ marginTop: { xs: "30px", sm: "30px", md: "0", lg: "0" }, width: { xs: "100%", sm: "50%", md: "50%", lg: "50%" }, display: "flex", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, alignItems: { xs: "baseline", sm: "baseline", md: "center", lg: "center" } }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <img src="/assets/MailOutlineRounded.svg" />
                            <Typography color="#757575">Mail Adresi: </Typography>
                        </Box>
                        <Typography marginLeft="5px" fontWeight="bold" color="#757575"> {email} </Typography>
                        <Button disableElevation
                            onClick={() => setEditMailModalOpen(true)}
                            sx={{
                                margin: theme.spacing(1),
                                width: { xs: "100%", sm: "100%", md: "auto", lg: "auto" },
                            }} variant="contained" color="primary">Değiştir</Button>
                    </Box> */}
                </Box>
                <Divider sx={{ marginTop: "30px" }}></Divider>
                <Typography marginTop="30px" color="#DC804F">Şifre İşlemleri</Typography>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, width: { xs: "100%", sm: "50%", md: "50%", lg: "50%" } }}>
                    <Typography color="#757575">Şifrenizi Değiştirmek İçin  <Button disableElevation
                        onClick={() => setChangePasswordModalOpen(true)}
                        sx={{
                            margin: theme.spacing(1),
                            width: { xs: "100%", sm: "100%", md: "auto", lg: "auto" },
                        }} variant="contained" color="primary">Tıklayınız</Button></Typography>

                </Box>
            </Paper>
            <Modal
                open={individualEditModalOpen}
                onClose={() => setIndividualEditModalOpen(false)}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    overflow: "auto"
                }}
            >
                <Paper sx={{
                    padding: theme.spacing(3),
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: {
                        xs: "90%",
                        sm: "90%",
                        md: "70%",
                        lg: "70%",
                    }
                }}
                    component="form"
                    onSubmit={handleEditIndividual}
                >
                    <Typography sx={{ margin: theme.spacing(1) }} variant="h6" component="h2">
                        Düzenle
                    </Typography>
                    <Grid container justifyContent="space-between" >

                        <TextField
                            variant="outlined"
                            label="Adı"
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "45%",
                                    lg: "48%",
                                },
                                margin: theme.spacing(1)
                            }}
                            required={true}
                            value={editFirstName}
                            onChange={(e) => {
                                setEditFirstName(e.target.value)
                            }}
                        />
                        <TextField
                            variant="outlined"
                            label="Soyadı"
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "45%",
                                    lg: "48%",
                                },
                                margin: theme.spacing(1)
                            }}
                            required={true}
                            value={editLastName}
                            onChange={(e) => {
                                setEditLastName(e.target.value)
                            }}
                        />
                        <TextField
                            variant="outlined"
                            label="Şehir"
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "45%",
                                    lg: "48%",
                                },
                                margin: theme.spacing(1)
                            }}
                            required={true}
                            select
                            value={editIndividualProvince}
                            onChange={(e) => {
                                setEditIndividualProvince(e.target.value)
                            }}
                        >
                            {
                                illerBireysel &&
                                illerBireysel.map((val, i) => {
                                    return <MenuItem key={i} value={val?.provinceName}>{val?.provinceName}</MenuItem>
                                })
                            }
                        </TextField>
                        <Button sx={{
                            margin: theme.spacing(1), width: {
                                xs: "100%",
                                sm: "100%",
                                md: "45%",
                                lg: "25%",
                            },
                        }} type="submit" variant="contained" color="primary">
                            Onayla
                        </Button>
                    </Grid>


                </Paper>
            </Modal>
            <Modal
                open={corporateEditModalOpen}
                onClose={() => setCorporateEditModalOpen(false)}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    overflow: "auto"
                }}
            >
                <Paper sx={{
                    padding: theme.spacing(3),
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: {
                        xs: "90%",
                        sm: "90%",
                        md: "70%",
                        lg: "70%",
                    }
                }}
                    component="form"
                    onSubmit={handleEditCorporate}
                >
                    <Typography sx={{ margin: theme.spacing(1) }} variant="h6" component="h2">
                        Oluştur
                    </Typography>
                    <Grid container justifyContent="space-between" >





                        <TextField
                            variant="outlined"
                            label="Şirket Unvanı"
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "45%",
                                    lg: "48%",
                                },
                                margin: theme.spacing(1)
                            }}
                            required={true}
                            value={editTitle}
                            onChange={(e) => {
                                setEditTitle(e.target.value)
                            }}
                        />
                        <TextField
                            variant="outlined"
                            label="Şehir"
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "45%",
                                    lg: "48%",
                                },
                                margin: theme.spacing(1)
                            }}
                            required={true}
                            select
                            value={editCorporateProvince}
                            onChange={(e) => {
                                setEditCorporateProvince(e.target.value)
                            }}
                        >
                            {
                                illerKurumsal &&
                                illerKurumsal.map((val, i) => {
                                    return <MenuItem key={i} value={val?.provinceName}>{val?.provinceName}</MenuItem>
                                })
                            }
                        </TextField>
                        <Button sx={{
                            margin: theme.spacing(1), width: {
                                xs: "100%",
                                sm: "100%",
                                md: "45%",
                                lg: "25%",
                            },
                        }} type="submit" variant="contained" color="primary">
                            Onayla
                        </Button>
                    </Grid>


                </Paper>
            </Modal>
            <Modal
                open={editPhoneModalOpen}
                onClose={() => setEditPhoneModalOpen(false)}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    overflow: "auto"
                }}
            >
                <Paper sx={{
                    padding: theme.spacing(3),
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: {
                        xs: "90%",
                        sm: "90%",
                        md: "70%",
                        lg: "70%",
                    }
                }}
                    component="form"
                    onSubmit={handleEditPhone}
                >
                    <Typography sx={{ margin: theme.spacing(1) }} variant="h6" component="h2">
                        Oluştur
                    </Typography>
                    <Grid container justifyContent="space-between" >





                        <TextField
                            variant="outlined"
                            label="Telefon Numarası"
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "45%",
                                    lg: "48%",
                                },
                                margin: theme.spacing(1)
                            }}
                            required={true}
                            value={editPhone}
                            onChange={(e) => {
                                setEditPhone(e.target.value)
                            }}
                        />

                        <Button sx={{
                            margin: theme.spacing(1), width: {
                                xs: "100%",
                                sm: "100%",
                                md: "45%",
                                lg: "25%",
                            },
                        }} type="submit" variant="contained" color="primary">
                            Onayla
                        </Button>
                    </Grid>


                </Paper>
            </Modal>
            <Modal
                open={verifyPhoneCodeModalOpen}
                onClose={() => setVerifyPhoneCodeModalOpen(false)}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    overflow: "auto"
                }}
            >
                <Paper sx={{
                    padding: theme.spacing(3),
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: {
                        xs: "90%",
                        sm: "90%",
                        md: "70%",
                        lg: "70%",
                    }
                }}
                    component="form"
                    onSubmit={handleVerifyPhone}
                >
                    <Typography sx={{ margin: theme.spacing(1) }} variant="h6" component="h2">
                        Oluştur
                    </Typography>
                    <Grid container justifyContent="space-between" >





                        <TextField
                            variant="outlined"
                            label="Onay Kodu"
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "45%",
                                    lg: "48%",
                                },
                                margin: theme.spacing(1)
                            }}
                            required={true}
                            value={verifyPhoneCode}
                            onChange={(e) => {
                                setVerifyPhoneCode(e.target.value)
                            }}
                        />

                        <Button sx={{
                            margin: theme.spacing(1), width: {
                                xs: "100%",
                                sm: "100%",
                                md: "45%",
                                lg: "25%",
                            },
                        }} type="submit" variant="contained" color="primary">
                            Onayla
                        </Button>
                    </Grid>


                </Paper>
            </Modal>
            <Modal
                open={editMailModalOpen}
                onClose={() => setEditMailModalOpen(false)}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    overflow: "auto"
                }}
            >
                <Paper sx={{
                    padding: theme.spacing(3),
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: {
                        xs: "90%",
                        sm: "90%",
                        md: "70%",
                        lg: "70%",
                    }
                }}
                    component="form"
                    onSubmit={handleEditMail}
                >
                    <Typography sx={{ margin: theme.spacing(1) }} variant="h6" component="h2">
                        Oluştur
                    </Typography>
                    <Grid container justifyContent="space-between" >





                        <TextField
                            variant="outlined"
                            label="Mail Adresi"
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "45%",
                                    lg: "48%",
                                },
                                margin: theme.spacing(1)
                            }}
                            required={true}
                            value={editMail}
                            onChange={(e) => {
                                setEditMail(e.target.value)
                            }}
                        />

                        <Button sx={{
                            margin: theme.spacing(1), width: {
                                xs: "100%",
                                sm: "100%",
                                md: "45%",
                                lg: "25%",
                            },
                        }} type="submit" variant="contained" color="primary">
                            Onayla
                        </Button>
                    </Grid>


                </Paper>
            </Modal>
            <Modal
                open={verifyMailCodeModalOpen}
                onClose={() => setVerifyMailCodeModalOpen(false)}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    overflow: "auto"
                }}
            >
                <Paper sx={{
                    padding: theme.spacing(3),
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: {
                        xs: "90%",
                        sm: "90%",
                        md: "70%",
                        lg: "70%",
                    }
                }}
                    component="form"
                    onSubmit={handleVerifyEmail}
                >
                    <Typography sx={{ margin: theme.spacing(1) }} variant="h6" component="h2">
                        Oluştur
                    </Typography>
                    <Grid container justifyContent="space-between" >





                        <TextField
                            variant="outlined"
                            label="Onay Kodu"
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "45%",
                                    lg: "48%",
                                },
                                margin: theme.spacing(1)
                            }}
                            required={true}
                            value={verifyMailCode}
                            onChange={(e) => {
                                setVerifyMailCode(e.target.value)
                            }}
                        />

                        <Button sx={{
                            margin: theme.spacing(1), width: {
                                xs: "100%",
                                sm: "100%",
                                md: "45%",
                                lg: "25%",
                            },
                        }} type="submit" variant="contained" color="primary">
                            Onayla
                        </Button>
                    </Grid>


                </Paper>
            </Modal>
            <Modal
                open={changePasswordModalOpen}
                onClose={() => setChangePasswordModalOpen(false)}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    overflow: "auto"
                }}
            >
                <Paper sx={{
                    padding: theme.spacing(3),
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: {
                        xs: "90%",
                        sm: "90%",
                        md: "70%",
                        lg: "70%",
                    }
                }}
                    component="form"
                    onSubmit={handleChangePassword}
                >
                    <Typography sx={{ margin: theme.spacing(1) }} variant="h6" component="h2">
                        Şifre Değiştir
                    </Typography>
                    <Grid container justifyContent="space-between" alignItems="center" flexDirection="column" >





                        <TextField
                            variant="outlined"
                            label="Eski Parola"
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "45%",
                                    lg: "48%",
                                },
                                margin: theme.spacing(1)
                            }}
                            type="password"
                            required={true}
                            value={oldPassword}
                            onChange={(e) => {
                                setOldPassword(e.target.value)
                            }}
                        />
                        <TextField
                            variant="outlined"
                            label="Yeni Parola"
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "45%",
                                    lg: "48%",
                                },
                                margin: theme.spacing(1)
                            }}
                            type="password"
                            required={true}
                            value={newPassword}
                            onChange={(e) => {
                                setNewPassword(e.target.value)
                            }}
                        />
                        <TextField
                            variant="outlined"
                            label="Yeni Parola (Tekrar)"
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "45%",
                                    lg: "48%",
                                },
                                margin: theme.spacing(1)
                            }}
                            type="password"
                            required={true}
                            value={newPasswordAgain}
                            onChange={(e) => {
                                setNewPasswordAgain(e.target.value)
                            }}
                        />
                        <Button sx={{
                            margin: theme.spacing(1), width: {
                                xs: "100%",
                                sm: "100%",
                                md: "45%",
                                lg: "25%",
                            },
                        }} type="submit" variant="contained" color="primary">
                            Onayla
                        </Button>
                    </Grid>


                </Paper>
            </Modal>

        </Box >
    )

}

export default Page
