import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

/**
 * suspense olursa menu kayıyor.
 */
// const kullanici_ayarlar = lazy(() => import('./pages/kullanici/ayarlar'));
// const kullanici_hesabim = lazy(() => import('./pages/kullanici/hesabim'));
// const kullanici_kullanicilar = lazy(() => import('./pages/kullanici/kullanicilar'));
import kullanici_ayarlar from '../pages/app/kullanici/ayarlar'
import kullanici_hesabim from '../pages/app/kullanici/hesabim'
import SuspenseFallback from './SuspenseFallback';
import PrivateRoute from './PrivateRoute';
import AdminRouter from './AdminRouter'
import nonSignalVehicles from '../pages/admin/non-signal-vehicles';

const not_found = lazy(() => import('../pages/app/NotFound'));
const cikis = lazy(() => import('../pages/app/cikis_yap'));
const index = lazy(() => import('../pages/app/index'));
const login = lazy(() => import('../pages/app/login'));
const register = lazy(() => import('../pages/app/register'));
const forgot_password = lazy(() => import('../pages/app/forgot-password'));
const reset_password = lazy(() => import('../pages/app/reset-password'));
const privacy = lazy(() => import('../pages/app/Privacy'));
const dashboard = lazy(() => import('../pages/app/dashboard'));

const tanimlar_araclar = lazy(() => import('../pages/app/tanimlar/araclar'));
const tanimlar_kullanicilar = lazy(() => import('../pages/app/tanimlar/kullanicilar'));
const tanimlar_guvenli_bolgeler = lazy(() => import('../pages/app/tanimlar/guvenli_bolgeler'));

const islemler_harita = lazy(() => import('../pages/app/islemler/harita'));
const islemler_takip = lazy(() => import('../pages/app/islemler/takip'));
const islemler_gecmis_takip = lazy(() => import('../pages/app/islemler/gecmis_takip'));

const raporlar_hareket_raporu = lazy(() => import('../pages/app/raporlar/hareket_raporu'));
const raporlar_hareket_raporu_sonuc = lazy(() => import('../pages/app/raporlar/alarmlar'));
const raporlar_bitmis_arizalar = lazy(() => import('../pages/app/raporlar/bitmis_arizalar'));
const raporlar_bitmis_bakimlar = lazy(() => import('../pages/app/raporlar/bitmis_bakimlar'));
const raporlar_aylik_bakim_raporu = lazy(() => import('../pages/app/raporlar/aylik_bakim_raporu'));
const raporlar_aylik_malzeme_raporu = lazy(() => import('../pages/app/raporlar/aylik_malzeme_raporu'));
const rota_takip = lazy(() => import('../pages/app/raporlar/rota_takip'));

const admin_index = lazy(() => import('../pages/admin/index'))
const admin = lazy(() => import('../pages/admin/login'))
const adminUsers = lazy(() => import('../pages/admin/users'))
const adminVehicles = lazy(() => import('../pages/admin/vehicles'))
const adminCommunity = lazy(() => import('../pages/admin/community'))
const adminPassiveUsers = lazy(() => import('../pages/admin/passive-users'))
const develop = lazy(() => import(`../pages/admin/develop`))



const Router = () => {

    return (
        <BrowserRouter>
            <Suspense fallback={<SuspenseFallback />}>
                <Switch>

                    <Route exact path="/" component={login} />
                    <Route exact path="/Login" component={login} />
                    {/*   <Route exact path="/register" component={register} /> */}
                    <Route exact path="/forgot-password" component={forgot_password} />
                    <Route exact path="/reset-password" component={reset_password} />

                    <Route exact path="/privacy" component={privacy} />

                    <PrivateRoute exact path="/app/kullanici/ayarlar" component={kullanici_ayarlar} />
                    <PrivateRoute exact path="/app/kullanici/hesabim" component={kullanici_hesabim} />

                    <PrivateRoute exact path="/app/tanimlar/araclar" component={tanimlar_araclar} />
                    <PrivateRoute exact path="/app/tanimlar/guvenli-bolgeler" component={tanimlar_guvenli_bolgeler} />
                    <PrivateRoute exact path="/app/tanimlar/kullanicilar" component={tanimlar_kullanicilar} />

                    <PrivateRoute exact path="/app/islemler/harita" component={islemler_harita} />
                    <PrivateRoute exact path="/app/islemler/takip" component={islemler_takip} />
                    <PrivateRoute exact path="/app/islemler/gecmis_takip" component={islemler_gecmis_takip} />

                    <PrivateRoute exact path="/app/raporlar/hareket-raporu" component={raporlar_hareket_raporu} />
                    <PrivateRoute exact path="/app/raporlar/alarmlar" component={raporlar_hareket_raporu_sonuc} />
                    <PrivateRoute exact path="/app/raporlar/bitmis-arizalar" component={raporlar_bitmis_arizalar} />
                    <PrivateRoute exact path="/app/raporlar/bitmis-bakimlar" component={raporlar_bitmis_bakimlar} />
                    <PrivateRoute exact path="/app/raporlar/aylik-bakim-raporu" component={raporlar_aylik_bakim_raporu} />
                    <PrivateRoute exact path="/app/raporlar/aylik-malzeme-raporu" component={raporlar_aylik_malzeme_raporu} />
                    <PrivateRoute exact path="/app/raporlar/aylik-malzeme-raporu" component={raporlar_aylik_malzeme_raporu} />
                    <PrivateRoute exact path="/app/raporlar/rota-takip" component={rota_takip} />

                    <PrivateRoute exact path="/app/logout" component={cikis} />

                    <PrivateRoute exact path="/app" component={dashboard} />

                    <AdminRouter exact path="/admin/index" component={admin_index} />
                    <Route exact path="/admin" component={admin} />
                    <AdminRouter exact path="/admin/users" component={adminUsers} />
                    <AdminRouter exact path="/admin/vehicles" component={adminVehicles} />
                    <AdminRouter exact path="/admin/community" component={adminCommunity} />
                    <AdminRouter exact path="/admin/passive-users" component={adminPassiveUsers} />
                    <AdminRouter exact path="/admin/develop" component={develop} />
                    <AdminRouter exact path="/admin/non-signal-vehicles" component={nonSignalVehicles} />

                    <Route component={not_found} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    )

}

export default Router

