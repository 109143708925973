import axios from 'axios';

export const forgotPasswordRequest = ({ phone }) => axios.post(`/account/forgotPassword`, { phone });

export const loginRequest = ({ loginType, password, rememberMe, username }) => axios.post(`/account/login`, { loginType, password, rememberMe, username });

export const logoutRequest = () => axios.post(`/account/logout`);

export const getMeRequest = () => axios.get(`/account/me`);

export const resetPasswordRequest = ({ newPassword, verificationCode }) => axios.post(`/account/resetPassword`, { newPassword, verificationCode });