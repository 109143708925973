import firebase from "firebase/app";
import { useMemo } from "react";

const firebaseConfig = {
    apiKey: "AIzaSyDK2rx2kHoypA6ybWCMPgJ8yQ6pMDu9k0M",
    authDomain: "weaver-aselsis.firebaseapp.com",
    projectId: "weaver-aselsis",
    storageBucket: "weaver-aselsis.appspot.com",
    messagingSenderId: "519039287798",
    appId: "1:519039287798:web:29aa232ef0e669a87007d7",
    measurementId: "G-Z1SR71LKHT"
}

const FirebaseProvider = ({ children }) => {


    useMemo(() => {
        try {
            firebase && firebase.initializeApp(firebaseConfig)            
        } catch (error) {}
    }, [firebase])

    return children
}


export default FirebaseProvider