import axios from 'axios';

export const findAllVehicleRequest = () => axios.get(`/vehicles`);

export const findAllDeletedVehicleRequest = () => axios.get(`/vehicles/deleted`);

export const findAllVehicleWithoutSignalRequest = () => axios.get(`/dashboard/vehiclesWithoutSignal`);

export const getMyVehiclesRequest = ({ id }) => axios.get(`/vehicles/myVehicles/${id}`);

export const updateVehicleRequest = ({ id, modelYear, totalDistance, vehicleBrandId, vehicleTypeId, vehicleUsageTypeId, simCardNum }) => axios.put(`/vehicles/${id}`, { modelYear, totalDistance, vehicleBrandId, vehicleTypeId, vehicleUsageTypeId, simCardNum });

export const getMyVehiclesSelectBoxRequest = ({ id }) => axios.get(`/vehicles/selectBox?communityId=${id}`);

export const findAllPageableVehicleRequest = ({ search, page, size }) => axios.get(`/vehicles/pageable?search=${search}&page=${page}&size=${size}`);

export const saveRequest = ({ communityIdList, imei, isSaveToMunicipality, licensePlate, modelYear, totalDistanceKm, vehicleBrandId, vehicleOwnerId, vehicleTypeId, vehicleUsageTypeId, simCardNum }) => axios.post(`/vehicles`, { communityIdList, imei, isSaveToMunicipality, licensePlate, modelYear, totalDistanceKm, vehicleBrandId, vehicleOwnerId, vehicleTypeId, vehicleUsageTypeId, simCardNum });

export const updateVehicleStatus = ({ vehicleId, vehicleStatus, force = false }) => axios.put(`/vehicles/${vehicleId}/status?force=${force}`, { vehicleStatus });

export const updateRegistiredBelediyeRequest = ({ id, isRegisteredBursaBelediye }) => axios.put(`/vehicles/${id}/registeredBursaBelediye`, { isRegisteredBursaBelediye });

export const updateVehicleImei = ({ vehicleId, newImei }) => axios.put(`/vehicles/${vehicleId}/imei`, { newImei });

export const resetVehicleImei = ({ vehicleId, newImei }) => axios.put(`/vehicles/${vehicleId}/imei/reset`, { newImei });

export const findByVehicleNameRequest = ({ licensePlate, page, size }) => axios.get(`/vehicles/pageable/licensePlate?licensePlate=${licensePlate}&page=${page}&size=${size}`);

export const findByVehicleId = ({ id }) => axios.get(`/vehicles/${id}`);

export const deleteVehicleByIdRequest = (id) => axios.delete(`/vehicles/${id}`)
