import { CssBaseline } from "@material-ui/core"
import CustomThemeProvider from "./CustomThemeProvider"
import Router from "./Router"
import React from 'react'
import ApiProvider from "../api/ApiProvider"
import FirebaseProvider from "./FirebaseProvider"


const App = () => {

  return (
    <CustomThemeProvider>
        <FirebaseProvider>
          <ApiProvider>
            <CssBaseline />
            <Router />
          </ApiProvider>
        </FirebaseProvider>
    </CustomThemeProvider>
  )
}

export default App
