import { blue } from '@material-ui/core/colors';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';


import { trTR } from '@material-ui/data-grid';
import { trTR as coreTrTR } from '@material-ui/core/locale';




const CustomThemeProvider = ({ children }) => {

    const theme = createTheme({
        palette: {
            primary: {
                main: "#C03A2A",
                contrastText: "#efefef"
            },
            secondary: {
                main: '#3F3F3F',
                contrastText: "#efefef"

            },
            background: {
                default: "#efefef",
            }
        },
    },
        coreTrTR,
        trTR,
    )

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )

}

export default CustomThemeProvider